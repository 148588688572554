<template>
  <div id="horario" class="titulos text-center">
    <h2>⏰ Horario 7ºA</h2>
  </div>
  <div class="container mt-4">
    <swiper :initial-slide="currentDayIndex" @slideChange="onSlideChange">
      <swiper-slide v-for="(day, index) in days" :key="index">
        <div class="day-container boxContent mx-2">
          <h2 class="text-center">{{ day }}</h2>
          <div v-html="content[day]"></div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

export default {
  name: "HorarioApp",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      days: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"],
      content: {
        Lunes:
          "<center>Salida: 17:05 hrs</center><br><p><kbd>08:15</kbd> 🧭 Orientación<br> <kbd>09:00</kbd> 👩🏻‍🎓 Consejo Curso<br> <kbd>09:45</kbd> 🎉 Recreo<br> <kbd>10:00</kbd> 📐 Matemáticas<br> <kbd>11:30</kbd> 🎉 Recreo<br> <kbd>11:40</kbd>  🏰 Historia<br> <kbd>13:10</kbd> 🥗 Almuerzo<br> <kbd>13:55</kbd> 🗣️ Inglés<br> <kbd>15:25</kbd> 🎉 Recreo<br> <kbd>15:35</kbd> 🌳 Ciencias Naturales<br<p>",
        Martes:
          "<center>Salida: 13:10 hrs</center><br><p><kbd>08:15</kbd> 🏃🏻‍♂️ Ed. Física<br> <kbd>09:45</kbd> 🎉 Recreo<br> <kbd>10:00</kbd> 📚 Lenguaje<br> <kbd>11:30</kbd> 🎉 Recreo<br> <kbd>11:40</kbd>  🎵 Música<p>",
        Miércoles:
          "<center>Salida: 15:25</center><br><p><kbd>08:15</kbd> 📐 Matemáticas<br> <kbd>09:45</kbd> 🎉 Recreo<br> <kbd>10:00</kbd> 🏰 Historia<br> <kbd>11:30</kbd> 🎉 Recreo<br> <kbd>11:40</kbd>  🎨 Artes<br> <kbd>13:10</kbd> 🥗 Almuerzo<br> <kbd>13:55</kbd> 📚 Lenguaje<p>",
        Jueves:
          "<center>Salida: 15:25</center><br><p><kbd>08:15</kbd> 💻 Tecnología<br> <kbd>09:00</kbd> 🗣️ Inglés<br> <kbd>09:45</kbd> 🎉 Recreo<br> <kbd>10:00</kbd> 🏃🏻‍♂️ Ed. Física<br> <kbd>11:30</kbd> 🎉 Recreo<br> <kbd>11:40</kbd>  📚 Lenguaje<br> <kbd>13:10</kbd> 🥗 Almuerzo<br> <kbd>13:55</kbd> 🌳 Ciencias Naturales<p>",
        Viernes:
          "<center>Salida: 13:10</center><br><p><kbd>08:15</kbd> 🌳 Ciencias Naturales<br> <kbd>09:45</kbd> 🎉 Recreo<br> <kbd>10:00</kbd> 😇 Religión<br> <kbd>11:30</kbd> 🎉 Recreo<br> <kbd>11:40</kbd> 📐 Matemáticas<p>",
      },
      currentDayIndex: new Date().getDay() - 1,
    };
  },
  methods: {
    onSlideChange(swiper) {
      this.currentDayIndex = swiper.activeIndex;
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.day-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

article {
  height: 100px;
  width: 100px;
  background-color: aqua;
}
</style>
