<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div id="theTop" class="text-center">
          <span>
            <img
              src="../assets/img_colegio-integrado.webp"
              alt="Logo Institución Cit"
            />
          </span>

          <span>
            <img
              src="../assets/img_septimo-cit.webp"
              alt="Logo Institución Cit"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
  <div id="menuNav" class="container-fluid sticky-top">
    <div class="row">
      <div class="col">
        <nav class="text-center">
          <ul>
            <li><a href="#horario">Horario</a></li>
            <li><a href="#calendario">Calendario</a></li>
            <li><a href="#noticias">Noticias</a></li>
            <li><a href="https://cuentas.napsis.cl/">Napsis</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
#theTop img {
  margin: 15px 0;
  max-width: 150px;
}

#menuNav {
  background-color: #070c68;
}

nav ul {
  padding: 10px 0 0 0;
}

nav li {
  list-style-type: none;
  display: inline-block;
  padding: 10px;
}

nav a {
  color: #fff;
}
nav a:hover {
  color: #eeb200;
}
</style>
