import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "swiper/swiper-bundle.css"; // Asegúrate de que esta ruta es correcta
import "./assets/estilos.css";

createApp(App).use(router).mount("#app");
