<template>
  <div id="calendario" class="container">
    <div class="row">
      <div class="col">
        <div class="titulos text-center">
          <h2>🗓️ Calendario 7ºA</h2>
        </div>
      </div>
    </div>

    <div class="row">
      <PreloaderApp v-if="loading" />
      <!-- prettier-ignore -->
      <div class="col-sm-12 col-md-6 col-lg-4" v-for="(event, index) in events" :key="index">
        <div class="boxContent mb-3">
          <h5>{{ event.title }}</h5>
          <div class="formatFecha">🗓️ {{ formatDate(event.startTime) }}</div>
          <hr>
          <div v-html="event.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PreloaderApp from "./PreloaderApp.vue";

export default {
  components: {
    PreloaderApp,
  },
  data() {
    return {
      events: [],
      loading: true,
    };
  },
  async created() {
    try {
      const response = await axios.get(
        "https://script.google.com/macros/s/AKfycbw4lhDdsygOlg8g72BDcD-15nxhFeC5l7qpnWXPCsdYnzDsBxCGAbhogOu2RK5LL_tX/exec"
      );
      this.events = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    formatDate(date) {
      const options = {
        weekday: "short",
        day: "numeric",
        month: "long",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      return (
        new Intl.DateTimeFormat("es-ES", options).format(new Date(date)) +
        " hrs."
      );
    },
  },
};
</script>

<style scoped>
.formatFecha {
  text-transform: capitalize;
}
</style>
