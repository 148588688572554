<template>
  <div>
    <EncabezadoApp />
    <HorarioApp />
    <CalendarEvents />
    <WordpressPosts />
    <FooterApp />
  </div>
</template>

<script>
import EncabezadoApp from "../components/EncabezadoApp.vue";
import HorarioApp from "../components/HorarioApp.vue";
import CalendarEvents from "../components/CalendarEvents.vue";
import WordpressPosts from "../components/WordpressPosts.vue";
import FooterApp from "../components/FooterApp.vue";

export default {
  components: {
    EncabezadoApp,
    HorarioApp,
    CalendarEvents,
    WordpressPosts,
    FooterApp,
  },
};
</script>
