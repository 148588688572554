<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <footer class="text-center my-5">
          <!-- prettier-ignore -->
          <p>Desarrollado por <a href="https://instagram.com/gristilo">Gristilo</a></p>
          <img src="../assets/logo-gristilo.webp" alt="Logotipo de Gristilo" />
        </footer>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
.container-fluid {
  background-color: #333333;
  color: #999;
}

footer img {
  max-width: 200px;
}
</style>
