<template>
  <div id="noticias" class="container">
    <div class="row">
      <div class="titulos text-center">
        <h2>📰 Últimas Noticias</h2>
      </div>
    </div>
    <div class="row">
      <!-- prettier-ignore -->
      <div class="col-sm-12 col-md-6 col-lg-4" v-for="post in posts" :key="post.id">
        <div class="boxContent mb-3">
          <h5>{{ post.title.rendered }}</h5>
          <p>🗓️ Publicado: {{ formatDate(post.date) }}</p>
          <hr>
          <div v-html="post.excerpt.rendered" class="excerpt-content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { format } from "date-fns";

export default {
  data() {
    return {
      posts: [],
    };
  },
  async created() {
    try {
      const response = await axios.get(
        "https://www.colegiointegrado.cl/wp-json/wp/v2/posts?_fields=excerpt,title,date"
      );
      this.posts = response.data;
    } catch (error) {
      console.error(error);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.processExcerpts();
    });
  },
  methods: {
    processExcerpts() {
      const excerpts = this.$el.querySelectorAll(".excerpt-content");
      excerpts.forEach((excerpt) => {
        const links = excerpt.querySelectorAll("a");
        links.forEach((link) => {
          link.setAttribute("target", "_blank");
        });
      });
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy");
    },
  },
  watch: {
    posts() {
      this.$nextTick(() => {
        this.processExcerpts();
      });
    },
  },
};
</script>

<style scoped>
.boxContent b {
  color: #999;
}
</style>
